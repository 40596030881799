import React, { CSSProperties, useMemo } from "react";
import {
  ArrowIcon,
  Button,
  Col,
  Csku,
  Row,
  useWindowSize,
} from "@commonsku/styles";
import DashboardPageTitle from "../../dashboard/DashboardPageTitle";
import ActionMenu from "../action-menu/ActionMenu";
import ErrorBoundary from "../../ErrorBoundary";
import {
  BrowserRouter,
  Link,
  Route,
  Routes,
  useParams,
} from "react-router-dom";
import NoMatch from "../../NoMatch";
import { ReportProvider } from "../report_context";
import { getReportConfig, ReportConfig } from "../config";
import ReportFiltersContainer from "../filters/ReportFiltersContainer";
import ReportSummaryContainer from "../summary/ReportSummaryContainer";
import ReportTable from "../table/ReportTable";
import Pagination from "../table/Pagination";
import Loading from "../Loading";
import { isUUID } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportFilters,
  getSavedReportsState,
} from "../../../redux/savedReports";
import { getPopups } from "../../../selectors";
import createPopup from "../../../popup-factory";

import { getReportTypeTitle } from "./helpers";
import { REPORT_TYPES } from "./report_types";
import HomePage from "../home/HomePage";
import { createLoadReportPopup } from "../../../actions/popup";
import { useHasCapabilities } from "../../../hooks";

const reportContainerStyle: CSSProperties = {
  width: "100%",
  minHeight: "100vh",
  height: "100%",
  background: "#E6EFF2",
  marginTop: 0,
  paddingTop: 24,
  paddingRight: 24,
};

const ReportContainer = () => {
  const popups = useSelector(getPopups);
  const params = useParams();
  const { param } = params;
  const report_id = isUUID(param) ? param : undefined;
  const { current_report } = useSelector(getSavedReportsState);
  const windowHeight = useWindowSize()[1];

  let report_type: string;
  if (!report_id) {
    report_type = param.toUpperCase();
  } else {
    report_type = current_report.type;
  }

  const defaultFilters = useSelector(getReportFilters(report_id));
  const reportConfig: ReportConfig = useMemo(
    () => getReportConfig(report_type),
    [report_type],
  );

  const capabilities = REPORT_TYPES?.[reportConfig.type]?.capabilities ?? [];
  const hasAccess = useHasCapabilities(capabilities);

  if (!reportConfig || !hasAccess) {
    return null;
  }

  return (
    <ReportProvider
      reportConfig={reportConfig}
      defaultFilters={defaultFilters}
      containerHeight={windowHeight}
    >
      <Csku as={"div"} className="main-content csku-styles">
        <Row>
          <Col
            xs
            sm={10}
            style={{ display: "flex", alignItems: "center", paddingLeft: 8 }}
          >
            <Link to={"/report"}>
              <ArrowIcon direction="left" size="huge" mr={10} />
            </Link>
            <DashboardPageTitle
              title={`${getReportTypeTitle(report_type)} Report`}
            />
          </Col>
          <Col xs sm={2} padded end={1}>
            <ActionMenu />
          </Col>
        </Row>

        <ReportFiltersContainer />
        <ReportSummaryContainer />
        <ReportTable />
        <Pagination />
        <Loading />
        {popups ? popups.map((p, idx) => createPopup(p, idx)) : null}
      </Csku>
    </ReportProvider>
  );
};

const ReportsHomeContainer = () => {
  const popups = useSelector(getPopups);
  const dispatch = useDispatch();
  const hasSaveLoadReport = useHasCapabilities(['HAS-SAVE-LOAD-REPORTS']);

  return (
    <Csku
      as={"div"}
      className="main-content csku-styles"
      style={reportContainerStyle}
    >
      <Row>
        <Col xs={8} style={{ paddingLeft: 8 }}>
          <DashboardPageTitle title="Reports" />
        </Col>
        <Col xs={4} padded end={1}>
          {hasSaveLoadReport &&
            <Button
              onClick={() => {
                dispatch(createLoadReportPopup());
                document.body.style.overflow = "hidden";
              }}
            >
              Load Report
            </Button>
        }
        </Col>
      </Row>
      <HomePage />
      {popups ? popups.map((p, idx) => createPopup(p, idx)) : null}
    </Csku>
  );
};

const ReportPageRouter = ({ location }) => {
  return (
    <BrowserRouter basename={location}>
      <Routes>
        <Route
          path="/report"
          element={<ReportsHomeContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/report/:param"
          element={<ReportContainer />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<NoMatch location={location} />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default ReportPageRouter;
